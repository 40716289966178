
import '../App.css';
import { Link } from 'react-router-dom'

function Nav({ Tab }) {
    function toggleMenu() {
        const menu = document.getElementById("mobileNavContainer");
        const menuicon = document.getElementById("menuIcon");
        menuicon.classList.toggle("close-button");
        menu.classList.toggle("open-menu");
    }

    return (
        <>
            <div className='mobile-nav' id="mobileNavContainer">
                <div className="mobile-nav-inner">

                    <a href="#project" onClick={() => toggleMenu()}>Home</a>
                    <div className="divider"></div>
                    <a href="#roadmap" onClick={() => toggleMenu()}>Roadmap</a>
                    <div className="divider"></div>
                    <a href="#team" onClick={() => toggleMenu()}>Team</a>
                    <div className="divider"></div>
                    <a href="#launch" onClick={() => toggleMenu()}>Join</a>
                    <div className="divider"></div>
                    <a href="#faq" onClick={() => toggleMenu()}>FAQs</a>
                    <div className="divider"></div>
                    <div className='social-icons'>
                        <a href="https://twitter.com/Cryptopia_FLR" target="_blank" rel="noreferrer"><img src="/assets/twitter.svg" alt="" /></a>
                        <a href="https://discord.gg/6TGNtzjrTM" target="_blank" rel="noreferrer"><img src="/assets/discord.svg" alt="" /></a>
                    </div>




                </div>


            </div>
            <div className="menu-button" id="menuIcon" onClick={() => toggleMenu()}>
                <div className="line-top"></div>
                <div className="line-bottom"></div>
            </div>
            <nav>
                <a href="#home" className='logoa'>
                    <div className='flexaudi'>
                        <div className='mainlogowrap'><img src="/logo512.png" className='mainlogo' alt="" /></div>

                    </div>
                </a>
                <div className='links hide-800'>
                    <a href="#roadmap">Roadmap</a>
                    <a href="#team">Team</a>
                    <a href="#launch">Join</a>
                    <a href="#faq">FAQs</a>
                    <div className='hide-800 social-icons'>

                        <a href="https://twitter.com/Cryptopia_FLR" target="_blank" rel="noreferrer"><img src="/assets/twitter.svg" alt="" /></a>
                        <a href="https://discord.gg/6TGNtzjrTM" target="_blank" rel="noreferrer"><img src="/assets/discord.svg" alt="" /></a>

                    </div>
                </div>
            </nav>
        </>
    );
}

export default Nav;
