import Nav from "../components/Nav";
import * as React from 'react';
import { useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Parallax } from 'react-scroll-parallax';

function Home() {
    const currentTab = "home";
    useEffect(() => {
        AOS.init({ disable: "mobile" });
    }, [])
    return (
        <>
            <div className="home">
                <Nav Tab={currentTab} />

                <div className="home-top section" id="home">
                    <h1>

                        <div className="letter">C</div>
                        <div className="letter">R</div>
                        <div className="letter">Y</div>
                        <div className="letter">P</div>
                        <div className="letter">T</div>
                        <div className="letter">O</div>
                        <div className="letter">P</div>
                        <div className="letter">I</div>
                        <div className="letter">A</div>
                    </h1>
                    <a href="/" rel="noreferrer"><button className="darkbg">Chance to level up coming soon</button></a>

                </div>
                <div className="project" id="project">
                    <div className="imgteam">

                        <img src="/assets/project.png" alt="" data-aos="zoom-in" />
                    </div>
                    <div className="text-part" data-aos="fade-left" data-aos-offset="300">
                        <div className="title">
                            <h3>THE</h3>
                            <h2>STORY</h2>
                        </div>
                        <p>
                            Welcome to Cryptopia, a unique NFT community where you can explore a mystical world full of mythical creatures. Start as a Hatchling and climb the ranks through the Phoenixes, the Wolves, the Stallions, and Gryphons to reach the top, where you'll find the 12 mighty Elder Dragons who will shape the future of the project.
                            <br /><br />
                            Along the way, unlock exclusive rewards, prizes, and access to our community events. As an elder dragon, you'll not only gain access to a treasure trove of cash but also have the power to help create new collections and steer the direction of the project.
                            <br /><br />
                            Join us on this exciting adventure today!
                        </p>
                    </div>
                </div>
                {/* <h3 className="carouseltitle">The Society Members</h3>
                <section class="slider-part bg-dark py-3">
                    <div class="marquee-wrapper">
                        <div class="container">
                            <div class="marquee-block">

                                <div class="marquee-inner to-left"><span>
                                    <div class="marquee-item"><img src="/assets/gallery/1.png" alt="1" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/2.png" alt="2" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/3.png" alt="3" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/4.png" alt="4" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/5.png" alt="5" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/6.png" alt="6" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/7.png" alt="7" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/8.png" alt="8" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/9.png" alt="9" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/10.png" alt="10" /></div>



                                </span><span>
                                        <div class="marquee-item"><img src="/assets/gallery/1.png" alt="1" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/2.png" alt="2" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/3.png" alt="3" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/4.png" alt="4" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/5.png" alt="5" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/6.png" alt="6" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/7.png" alt="7" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/8.png" alt="8" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/9.png" alt="9" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/10.png" alt="10" /></div>

                                    </span></div>
                            </div>

                        </div>
                    </div>

                </section> */}
                <div class="timeline" id="roadmap">

                    <div className="text-part">
                        <div className="title" data-aos="zoom-in">
                            <h2>ROADMAP</h2>
                        </div>
                    </div>
                    <div class="timeline__event  animated  timeline__event--type1">
                        <div class="timeline__event__icon" data-aos="fade-right">
                            <img src="/assets/Roadmap_1.png" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            1
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">
                                <div className="labels">
                                    <span>Hatchling</span>

                                </div>
                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    Welcome to Cryptopia, where you'll embark on a journey through a mystical realm teeming with wondrous creatures. As you begin your adventure, you'll be joined by the Hatchlings, the youngest and most curious of the creatures. These playful beings are always eager to learn and explore, and they'll guide you through the first steps of your journey.                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="timeline__event animated timeline__event--type2">
                        <div class="timeline__event__icon" data-aos="fade-left">
                            <img src="/assets/Roadmap_2.png" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            2
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">

                                <div className="labels">
                                    <span>Phoenixes</span>
                                </div>
                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    Next, you'll encounter the Phoenixes, creatures of flame and rebirth. These fiery creatures symbolize transformation and change, and they'll help you to harness the power of change to unlock your full potential.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="timeline__event animated  timeline__event--type1">
                        <div class="timeline__event__icon" data-aos="fade-left">
                            <img src="/assets/Roadmap_3.png" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            3
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">

                                <div className="labels">
                                    <span>Wolves</span>
                                </div>
                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    Wolves are known for their loyalty and pack mentality, making them highly social creatures. They are powerful predators that can take down prey much larger than themselves. Their keen senses and endurance make them excellent trackers, able to traverse great distances in search of food.                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="timeline__event animated  timeline__event--type1">
                        <div class="timeline__event__icon" data-aos="fade-left">
                            <img src="/assets/Roadmap_4.png" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            4
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">

                                <div className="labels">
                                    <span>Stallions</span>
                                </div>
                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    Stallions are majestic creatures, renowned for their speed and strength. They are powerful mounts that can carry their riders great distances with ease. Their agility and grace make them ideal for acrobatic maneuvers, such as leaping over obstacles or charging into battle.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="timeline__event animated  timeline__event--type3">
                        <div class="timeline__event__icon" data-aos="fade-right">
                            <img src="/assets/Roadmap_5.png" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            5
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">
                                <div className="labels">
                                    <span>Gryphons</span>
                                </div>

                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    As you reach the pinnacle of your journey, you'll come face to face with the Gryphons, proud and powerful creatures of myth and legend. With their sharp talons and piercing eyes, they'll guide you to the final challenge. They will be able to recommend things to the Elder Dragons for their consideration, including proposals for muting people or steers on the best way forwards. They share a private channel with the Elder Dragons. They’ll also share the benefits of Cryptopia’s further ventures with the Elder Dragons
                                </p>
                            </div>

                        </div>
                    </div>
                    <div class="timeline__event animated  timeline__event--type1">
                        <div class="timeline__event__icon" data-aos="fade-left">
                            <img src="/assets/Roadmap_6.png" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            6
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">

                                <div className="labels">
                                    <span>Elder Dragons</span>
                                </div>
                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    And finally, you'll meet the 12 Elder Dragons, the leaders of Cryptopia. These wise and ancient beings are the keepers of the secrets of Cryptopia, and they hold the key to unlocking the ultimate power and wisdom. They will be able to vote on what to do with the treasure trove, from cashing out to using some to build the project further, propose the release of a coin or game etc. They cannot be messed with and will have additional powers of muting members for example and have their own channel for all to be planned and mapped out.
                                    <br /><br />
                                    The total amount in the Treasure will be updated live on Discord and increased until there are 12 Dragons.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="prologue-wrap">
                    <div className="text-part center">
                        <div className="title">
                            <h2>Join us!</h2>
                            <p>
                                Join us on this adventure and climb the ranks from Hatchling to Elder Dragon, unlocking new powers and abilities along the way. Together, we'll unlock the secrets of the Cryptopia and build a community of passionate and imaginative adventurers.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="project">
                    <div className="imgteam">

                        <img src="/assets/process.png" alt="" data-aos="zoom-in" />
                    </div>
                    <div className="text-part" data-aos="fade-left" data-aos-offset="300">
                        <div className="title">
                            <h3>THE</h3>
                            <h2>PROCESS</h2>
                        </div>
                        <p>
                            A journey to create your own future in Flare
                            <br /><br />
                            At regular periods that’ll be announced in Discord, a limited series of tickets will be made available for minting. Each will hold an equal chance of being selected for a ‘level up’. Each ticket that is randomly selected for a ‘level up’ allows the owner of that NFT to raise a support-ticket in Discord and choose which of their creatures they would like to replace with one from the rank above.
                            <br /><br />
                            IMPORTANT: To ‘level up’ the creature of your choice which you must be the holder of, you’ll have to burn it first. You will then be airdropped a creature from the rank above at random. The only rank this does not apply for is Hatchlings who are automatically airdropped and Dragons that will be randomly selected via a separate picker and a mix of methods. You only do this once you’ve confirmed it in your support-ticket on Discord with the team.
                        </p>
                    </div>
                </div>
                <div className="prologue-wrap">
                    <div className="team-wrap" id="team">
                        <div className="title">

                            <h2>THE <span>TEAM</span></h2>
                        </div>
                        <div className="team">

                            <div className="imgteam">

                                <img src="/assets/team.png" alt="" data-aos="zoom-in" />
                            </div>
                            <div className="text-part" data-aos="fade-left">
                                <p>
                                    Our team comprises of Web3 consultants, graphic designers / 3D modelers who’ve been part of multiple projects over the past 2 years. This project has come about from a desire to use these skills to create a strong community with products that our ultimately truly community-owned where people have a genuine chance of leading. The opportunity to become an Elder Dragon is equal and fair to all.
                                    <br /><br />
                                    Cryptopia is our passion project, bringing together amazing artwork, lore and real tangible generous benefits to holders.
                                </p>
                            </div>


                        </div>
                    </div>
                </div>
                <div id="launch">
                    <div className="prologue launch">
                        <div className="text-part" data-aos="fade-right">
                            <div className="title">
                                <h2>Want to join?</h2>
                            </div>

                            <p>
                                The first Ring of Fire will be announced on Discord very soon.
                                <br /><br />
                                Follow on there and on Twitter to make sure you’re up to date and when it opens, you’re free to mint as many tickets as you like. A small number of winners will be selected to Level Up to Hatchling level, receiving the first Lengendary Elevation Handshake as an immediate cash prize paid out in FLR. You’ll also be airdropped your own unique Hatchling.
                            </p>
                            <a href="https://discord.gg/6TGNtzjrTM" target="_blank" rel="noreferrer"><button className="minw">Join us on Discord</button></a> <br />
                            <a href="https://twitter.com/Cryptopia_FLR" target="_blank" rel="noreferrer"><button>Follow us on Twitter</button></a>
                        </div>
                        <img src="/assets/wanttojoin.png" alt="" data-aos="fade-left" />


                    </div>
                </div>

                <div className="prologue-wrap">
                    <div className="faq" id="faq">
                        <div className="imgteam">

                            <img src="/assets/faq.png" className="rounded" alt="" data-aos="zoom-in" />
                        </div>
                        <div className="text-part faqq">
                            <div className="title">
                                <h3>FREQUENTLY ASKED</h3>
                                <h2>QUESTIONS</h2>
                            </div>
                            <br /><br />
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography><span>1.</span> What can the Elder Dragons do with the treasure chest cash?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        This is entirely up to them. they can cash out entirely, or put a portion / all of it towards pushing Cryptopia further using the core team to do so. The 12 dragons won’t all be selected at random, they’ll be able to choose 1 or 2 members from any rank that they think have the skillset to benefit the entire community.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography><span>2.</span> When is the minting date?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        In order to achieve Hatchling level you’ll need to mint at least 1 ‘ticket’ from the first Ring of Fire. This will open up soon with a draw date announced at the same time. From this, a number of winners will be randomly selected using a Discord bot and they will be airdropped the first unique Hatchlings. This will open up a private channel in Discord, games and they’ll also receive a golden handshake in the form of a large instant FLR transfer.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography><span>3.</span> Where can i mint?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        The mint tab at the top right will become active as soon as it opens up. You’ll be able to mint as many tickets as you like which increases your chances of being selected. Remember that you cannot mint the level above directly. If one of your tickets is selected, you’ll be airdropped your creature automatically. If you’re going up a level, this will mean you first burning the creature you are choosing to level up, so expect the top ranking creatures to be in high demand!
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography><span>4.</span> Which wallet should I use?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Metamask or Bifrost are the ones we recommend.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography><span>5.</span> What will the mint price be?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        This project is about being community driven and therefore community-owned. That means that we’ve priced the tickets very low to allow for anyone to take part and have a chance of becoming an Elder Dragon or a Gryphon.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>
                <div className="discord-banner-wrap">
                    <div className="discord-banner">
                        <div className="text-part">
                            <div className="title">
                                <h3>JOIN US ON</h3>
                                <h2>DISCORD</h2>
                            </div>

                            <a href="https://discord.gg/6TGNtzjrTM" rel="noreferrer" target="_blank"><button>Connect with us</button></a>
                        </div>
                        <img src="/assets/logo.png" className="roundedfull" alt="" data-aos="zoom-in" />
                    </div>
                </div>
                <footer>
                    <Link className='mainlogowrap' to="/"><img src="/logo512.png" className='mainlogo' alt="" />Copyright ©2023 Cryptopia. All Rights Reserved</Link>
                    <div className=' social-icons'>
                        <a href="https://twitter.com/Cryptopia_FLR" target="_blank" rel="noreferrer"><img src="/assets/twitter.svg" alt="" /></a>
                        <a href="https://discord.gg/6TGNtzjrTM" target="_blank" rel="noreferrer"><img src="/assets/discord.svg" alt="" /></a>

                    </div>
                </footer>
            </div>
        </>
    )
}
export default Home;